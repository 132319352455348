import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import Fab from '@mui/material/Fab';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import map from 'lodash/fp/map';
import every from 'lodash/fp/every';
import identity from 'lodash/fp/identity';
import isEmpty from 'lodash/fp/isEmpty';
import trim from 'lodash/fp/trim';
import isNaN from 'lodash/fp/isNaN';
import isNull from 'lodash/fp/isNull';
import isInteger from 'lodash/fp/isInteger';
import find from 'lodash/fp/find';
import isUndefined from 'lodash/fp/isUndefined';
import replace from 'lodash/fp/replace';
import { addDays, isBefore, parseISO, differenceInYears } from 'date-fns/fp'
import { useNavigate } from 'react-router-dom';

import tango from './tango.png';
import { Translations } from './Translations';
import LanguageContext from './LanguageContext';
//import { PassengerI } from '../Datatypes/Datatypes';
//import Sexes from '../Services/Sexes';
//import Discounts from '../Services/Discounts';

/*interface PassengerInfoProps {
  passengerInfo: PassengerI;
  showValidation: Boolean;
  discountChange: () => void;
  sexes: Array<any>;
  discounts: Array<any>;
}*/

const translations: Translations = {
  en: {
    FULLNAME: 'Fullname (*)',
    LASTNAME: 'Lastname (*)',
    NATIONALITY: 'Nationality (*)',
    DOB: 'Years of dancing (*)',
    SEX: 'Sex (*)',
    MAN: 'Unique Islander Number',
    PHONE: 'Phone (*)',
    EMAIL: 'email (*)',
    SPECIALCARE: 'Special care info',
    CATEGORY: 'Category',
    CATEGORY_EMPTY: 'Category empty',
    REQUIRED: '(*) Required field',
    CITY: 'City (*)',
    COUNTRY: 'Country (*)',
    MALE: 'Male',
    FEMALE: 'Female',
    GENDER: 'Gender',
    ROLE: 'Role',
    LEADER: 'Leader',
    FOLLOWER: 'Follower',
    COUPLE: 'Couple',
    COUPLEFULLNAME: 'Couple\'s fullname (*)',
    PAYMENT: 'I will pay by:',
    BANK: 'Bank transfer',
    VIVA: 'Viva Wallet',
    OTHER: 'Other',
    PAYMENT_METHOD: 'Payment method (*)',
    ERRORS: 'Errors',
    FULLNAME_EMPTY: 'Fullname empty',
    CITY_EMPTY: 'City empty',
    COUNTRY_EMPTY: 'Country empty',
    DANCING_YEARS_EMPTY: 'Dancing years is not a number',
    PHONE_EMPTY: 'Phone empty',
    EMAIL_EMPTY: 'Email empty',
    COUPLE_FULLNAME_EMPTY: 'Couple fullname empty',
    PAYMENT_METHOD_EMPTY: 'Payment method empty',
    FULL_PASS: 'Marathon full pass 110€ includes:',
    FULL_PASS_ALSO: 'Full pass also includes:',
    FRIDAY_AFTERNOON: 'Milonga Friday aftrenoon',
    FRIDAY_NIGHT: 'Milonga Friday night',
    SATURDAY_NOON: 'Milonga Saturday noon',
    SATURDAY_NIGHT: 'Milonga Saturday night',
    SUNDAY_NOON: 'Milonga Sunday noon'
  },
  el: {
    FULLNAME: 'Ονοματεπώνυμο (*)',
    LASTNAME: 'Επώνυμο (*)',
    NATIONALITY: 'Εθνικότητα (*)',
    DOB: 'Έτη που χορεύετε (*)',
    SEX: 'Φύλλο (*)',
    MAN: 'ΜΑΝ',
    PHONE: 'Τηλ. (*)',
    EMAIL: 'email (*)',
    SPECIALCARE: 'Πληροφορίες ειδικής φροντίδας',
    CATEGORY: 'Κατηγορία',
    CATEGORY_EMPTY: 'Η κατηγορία είναι κενή',
    REQUIRED: '(*) Υποχρεωτικό πεδίο',
    CITY: 'Πόλη (*)',
    COUNTRY: 'Χώρα (*)',
    MALE: 'Άνδρας',
    FEMALE: 'Γυναίκα',
    GENDER: 'Φύλο',
    ROLE: 'Ρόλος',
    LEADER: 'Καβαλλιέρος',
    FOLLOWER: 'Ντάμα',
    COUPLE: 'Ζευγάρι',
    COUPLEFULLNAME: 'Ονοματεπώνυμο ζευγαριού (*)',
    PAYMENT: 'Θα πληρώσω με:',
    BANK: 'Τραπεζική εντολή',
    VIVA: 'Viva Wallet',
    OTHER: 'Αλλιώς',
    PAYMENT_METHOD: 'Τρόπος πληρωμής (*)',
    ERRORS: 'Λάθη',
    FULLNAME_EMPTY: 'Το ονοματεπώνυμο είναι κενό',
    CITY_EMPTY: 'Η πόλη είναι κενή',
    COUNTRY_EMPTY: 'Η χώρα είναι κενή',
    DANCING_YEARS_EMPTY: 'Τα έτη χορού δεν είναι αριθμός',
    PHONE_EMPTY: 'Το τηλέφωνο είναι κενό',
    EMAIL_EMPTY: 'Το email είναι κενό',
    COUPLE_FULLNAME_EMPTY: 'Το ονοματεπώνυμο του ζευγαριού είναι κενό',
    PAYMENT_METHOD_EMPTY: 'Ο τρόπος πληρωμής είναι κενός',
    FULL_PASS: 'Marathon full pass 110€ περιλαμβανει:',
    FULL_PASS_ALSO: 'Το full pass περιλαμβάνει επίσης:',
    FRIDAY_AFTERNOON: 'Milonga Παρασκευή απόγευμα',
    FRIDAY_NIGHT: 'Milonga Παρασκευή βράδυ',
    SATURDAY_NOON: 'Milonga Σάββατο μεσημέρι',
    SATURDAY_NIGHT: 'Milonga Σάββατο βράδυ',
    SUNDAY_NOON: 'Milonga Κυριακή μεσημέρι'
  }
}

const nonDigit = /\D/g;
const uniqueIslanderNumberChunk = /\d{3}/g;

export default function RegistrantInfo() {
  const language = React.useContext(LanguageContext);
  const langTranslations = translations[language.code];
  const [showValidation, setShowValidation] = React.useState<boolean>(false);
  const [fullname, setFullname] = React.useState<string>('');
  const [city, setCity] = React.useState<string>('');
  const [country, setCountry] = React.useState<string>('');

//  const [nationality, setNationality] = React.useState<string>(props.passengerInfo.nationality);
  const [dateOfBirth, setDateOfBirth] = React.useState<string>('');
/*  const [sex, setSex] = React.useState<string>(props.passengerInfo.sex);
  const [uniqueIslanderNumber, setUniqueIslanderNumber] = React.useState<string>(props.passengerInfo.uniqueIslanderNumber);*/
  const [phone, setPhone] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [gender, setGender] = React.useState<string>('male');
  const [role, setRole] = React.useState<string>('leader');
  const [couple, setCouple] = React.useState<boolean>(false);
  const [coupleFullname, setCoupleFullname] = React.useState<string>('');
  const [payment, setPayment] = React.useState<string>('other');
  const [paymentMethod, setPaymentMethod] = React.useState<string>('');
  const navigate = useNavigate();

  const validators = [
    () => !isEmpty(trim(fullname)),
    () => !isEmpty(trim(city)),
    () => !isEmpty(trim(country)),
    () => isInteger(parseInt(dateOfBirth)),
    () => !isEmpty(trim(phone)),
    () => !isEmpty(trim(email)),
    () => (!couple) || couple && !isEmpty(trim(coupleFullname)),
    () => (payment != 'other') || (payment == 'other' && !isEmpty(trim(paymentMethod)))
  ];
  const handleContinue = () => {
    const validate = map((validator: any) => validator())(validators);
    const valid = every(identity, validate);

    if (!valid) setShowValidation(true)
    else {
      localStorage.clear();
      localStorage.setItem('fullName', fullname);
      localStorage.setItem('city', city);
      localStorage.setItem('country', country);
      localStorage.setItem('dob', dateOfBirth);
      localStorage.setItem('phone', phone);
      localStorage.setItem('email', email);
      localStorage.setItem('role', role);
      localStorage.setItem('couple', couple.toString());
      localStorage.setItem('coupleFullName', coupleFullname);
      localStorage.setItem('payment', payment);
      localStorage.setItem('paymentMethod', paymentMethod);
      navigate('/terms');
    }
//
  }
/*  const [specialCareInfo, setSpecialCareInfo] = React.useState<string>(props.passengerInfo.specialCareInfo);
  const [sexOption, setSexOption] = React.useState<any | null>(null);
  const [discountOption, setDiscountOption] = React.useState<any | null>(null);
  const today = new Date();
  const isBeforeToday = isBefore(addDays(-1, today));
  const dob = parseISO(dateOfBirth);
  const age = isNaN(dob.getTime()) ? 0 : differenceInYears(dob, today);*/

//  console.log(age);
  const handleFullnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//    props.passengerInfo.firstName = event.target.value;
    setFullname(event.target.value);
  }
  const handleCoupleFullnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//    props.passengerInfo.firstName = event.target.value;
    setCoupleFullname(event.target.value);
  }
  const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//    props.passengerInfo.firstName = event.target.value;
    setGender(event.target.value);
  }
  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//    props.passengerInfo.firstName = event.target.value;
    setRole(event.target.value);
  }
  const handleCoupleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCouple(event.target.checked);
  };
  const handlePaymentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//    props.passengerInfo.firstName = event.target.value;
    setPayment(event.target.value);
  }
  const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//    props.passengerInfo.firstName = event.target.value;
    setPaymentMethod(event.target.value);
  }
/*  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//    props.passengerInfo.lastName = event.target.value;
    setLastName(event.target.value);
  }*/

/*  const handleNationalityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.passengerInfo.nationality = event.target.value;
    setNationality(event.target.value);
  }
*/
  const handleDateOfBirthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
/*    props.passengerInfo.dateOfBirth = event.target.value;
    const dob = parseISO(props.passengerInfo.dateOfBirth);
    const age = isNaN(dob.getTime()) ? 0 : differenceInYears(dob, today);
    const selected = find((discount: any) => {
      return age >= discount.age_start && (age <= discount.age_end || isNull(discount.age_end));
    })(props.discounts);

    props.passengerInfo.discount = isUndefined(selected) ? '' : selected.id;
    props.discountChange();*/
    setDateOfBirth(event.target.value);
  }

/*  const handleSexChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.passengerInfo.sex = event.target.value;
    setSex(event.target.value);
  }

  const handleUniqueIslanderNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const onlyDigits = replace(nonDigit, '', event.target.value);
    const withDahes = replace(uniqueIslanderNumberChunk, '$&-', onlyDigits);

    props.passengerInfo.uniqueIslanderNumber = onlyDigits;
    setUniqueIslanderNumber(withDahes);
  }
*/
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//  props.passengerInfo.phone = event.target.value;
    setPhone(event.target.value);
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//    props.passengerInfo.email = event.target.value;
    setEmail(event.target.value);
  }

  const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//  props.passengerInfo.phone = event.target.value;
    setCity(event.target.value);
  }

  const handleCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//  props.passengerInfo.phone = event.target.value;
    setCountry(event.target.value);
  }

/*  const handleSpecialCareInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.passengerInfo.specialCareInfo = event.target.value;
    setSpecialCareInfo(event.target.value);
  }*/

  return (
    <Paper sx={{ p: 1, mb: 1, mx: 1, maxWidth: { xs: 450, sm: 600 } }}>
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2
        }}
      >
        <PersonIcon
          color="secondary"
          sx={{
            height: 35,
            width: 35,
          }}
        />
        <Box
          component="img"
          sx={{
            maxHeight: { xs: 43, md: 43 },
            maxWidth: { xs: 100, md: 100 },
          }}
          alt="The house from the offer."
          src={tango}
        />
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={8} sm={5}>
          <TextField
            variant="standard"
            label={langTranslations.FULLNAME}
            sx={{ mb: 1 }}
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={fullname}
            onChange={handleFullnameChange}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            variant="standard"
            label={langTranslations.DOB}
            sx={{ mb: 1 }}
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={dateOfBirth}
            onChange={handleDateOfBirthChange}
          />
        </Grid>
        <Grid item xs={8} sm={4}>
          <TextField
            variant="standard"
            label={langTranslations.PHONE}
            sx={{ mb: 1 }}
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={phone}
            onChange={handlePhoneChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            label={langTranslations.EMAIL}
            sx={{ mb: 1 }}
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={email}
            onChange={handleEmailChange}
          />
        </Grid>
        <Grid item xs={8} sm={3}>
          <TextField
            variant="standard"
            label={langTranslations.CITY}
            sx={{ mb: 1 }}
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={city}
            onChange={handleCityChange}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            variant="standard"
            label={langTranslations.COUNTRY}
            sx={{ mb: 1 }}
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={country}
            onChange={handleCountryChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack direction="column" >
            <FormControl>
              <FormLabel>{langTranslations.GENDER}</FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="gender"
                value={gender}
                onChange={handleGenderChange}
              >
                <FormControlLabel
                  value="female"
                  control={<Radio color="secondary"/>}
                  label={langTranslations.FEMALE}
                />
                <FormControlLabel
                  value="male"
                  control={<Radio  color="secondary"/>}
                  label={langTranslations.MALE}
                />
              </RadioGroup>
            </FormControl>
            <FormControl>
              <FormLabel>{langTranslations.ROLE}</FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="role"
                value={role}
                onChange={handleRoleChange}
              >
                <FormControlLabel
                  value="leader"
                  control={<Radio color="secondary"/>}
                  label={langTranslations.LEADER}
                />
                <FormControlLabel
                  value="follower"
                  control={<Radio  color="secondary"/>}
                  label={langTranslations.FOLLOWER}
                />
              </RadioGroup>
            </FormControl>
            <FormControlLabel
              control={<Checkbox checked={couple} onChange={handleCoupleChange}  color="secondary"/>}
              label={langTranslations.COUPLE}
            />
            {couple &&
              <TextField
                variant="standard"
                label={langTranslations.COUPLEFULLNAME}
                sx={{ mb: 1 }}
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={coupleFullname}
                onChange={handleCoupleFullnameChange}
              />
            }
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack direction="column" >
            <FormControl>
              <FormLabel>{langTranslations.PAYMENT}</FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="payment"
                value={payment}
                onChange={handlePaymentChange}
              >
                <FormControlLabel
                  value="bank"
                  control={<Radio color="secondary"/>}
                  label={langTranslations.BANK}
                />
                <FormControlLabel
                  value="viva"
                  control={<Radio color="secondary"/>}
                  label={langTranslations.VIVA}
                />
                <FormControlLabel
                  value="other"
                  control={<Radio color="secondary"/>}
                  label={langTranslations.OTHER}
                />
              </RadioGroup>
            </FormControl>
            {payment == 'other' &&
              <TextField
                variant="standard"
                label={langTranslations.PAYMENT_METHOD}
                sx={{ mb: 1 }}
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={paymentMethod}
                onChange={handlePaymentMethodChange}
              />
            }
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Typography fontStyle='italic' variant='caption'>
            {langTranslations.REQUIRED}
          </Typography>
        </Grid>
        {showValidation &&
          <Grid item xs={12}>
            <Alert severity="error" sx={{mt: 2}}>
              <AlertTitle>{langTranslations.ERRORS}</AlertTitle>
              <List dense={true}>
              {isEmpty(trim(fullname)) &&
                <ListItem disablePadding>
                  <ListItemText primary={langTranslations.FULLNAME_EMPTY} />
                </ListItem>
              }
              {isEmpty(trim(city)) &&
                <ListItem disablePadding>
                  <ListItemText primary={langTranslations.CITY_EMPTY} />
                </ListItem>
              }
              {isEmpty(trim(country)) &&
                <ListItem disablePadding>
                  <ListItemText primary={langTranslations.COUNTRY_EMPTY} />
                </ListItem>
              }
              {!isInteger(parseInt(dateOfBirth)) &&
                <ListItem disablePadding>
                  <ListItemText primary={langTranslations.DANCING_YEARS_EMPTY} />
                </ListItem>
              }
              {isEmpty(trim(phone)) &&
                <ListItem disablePadding>
                  <ListItemText primary={langTranslations.PHONE_EMPTY} />
                </ListItem>
              }
              {isEmpty(trim(email)) &&
                <ListItem disablePadding>
                  <ListItemText primary={langTranslations.EMAIL_EMPTY} />
                </ListItem>
              }
              {couple && isEmpty(trim(coupleFullname)) &&
                <ListItem disablePadding>
                  <ListItemText primary={langTranslations.COUPLE_FULLNAME_EMPTY} />
                </ListItem>
              }
              {payment == 'other' && isEmpty(trim(paymentMethod)) &&
                <ListItem disablePadding>
                  <ListItemText primary={langTranslations.PAYMENT_METHOD_EMPTY} />
                </ListItem>
              }
              </List>
            </Alert>
          </Grid>
        }
        <Grid item xs={12} sm={6} >
          <Typography variant="body1">
          {langTranslations.FULL_PASS}
          </Typography>
          <Typography variant="body2">
          (salads, food, drinks)
          </Typography>
          <Typography variant="body1">
          Premilonga 10€
          </Typography>
          <Typography variant="body1">
          After milonga 10€
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} >
          <List
            dense={true}
            sx={{ listStyleType: 'disc', pl: 4 }}
            subheader={
              <ListSubheader component="div" >
                {langTranslations.FULL_PASS_ALSO}
              </ListSubheader>
            }
          >
            <ListItem  sx={{ display: 'list-item' }} disablePadding>
              <ListItemText primary={langTranslations.FRIDAY_AFTERNOON} />
            </ListItem>
            <ListItem  sx={{ display: 'list-item' }} disablePadding>
              <ListItemText primary={langTranslations.FRIDAY_NIGHT} />
            </ListItem>
            <ListItem  sx={{ display: 'list-item' }} disablePadding>
              <ListItemText primary={langTranslations.SATURDAY_NOON} />
            </ListItem>
            <ListItem  sx={{ display: 'list-item' }} disablePadding>
              <ListItemText primary={langTranslations.SATURDAY_NIGHT} />
            </ListItem>
            <ListItem  sx={{ display: 'list-item' }} disablePadding>
              <ListItemText primary={langTranslations.SUNDAY_NOON} />
            </ListItem>
          </List>
        </Grid>
      </Grid>

{/*      {props.showValidation &&
        <Alert severity="error" sx={{mt: 2}}>
          <AlertTitle>{langTranslations.ERRORS}</AlertTitle>
          <List dense={true}>
          {isEmpty(trim(firstName)) &&
            <ListItem disablePadding>
              <ListItemText primary={langTranslations.FIRST_NAME_EMPTY} />
            </ListItem>
          }
          {isEmpty(trim(lastName)) &&
            <ListItem disablePadding>
              <ListItemText primary={langTranslations.LAST_NAME_EMPTY} />
            </ListItem>
          }
          {isEmpty(trim(nationality)) &&
            <ListItem disablePadding>
              <ListItemText primary={langTranslations.NATIONALITY_EMPTY} />
            </ListItem>
          }
          {isEmpty(trim(dateOfBirth)) &&
            <ListItem disablePadding>
              <ListItemText primary={langTranslations.DOB_EMPTY} />
            </ListItem>
          }
          {!isBeforeToday(parseISO(dateOfBirth)) &&
            <ListItem disablePadding>
              <ListItemText primary={langTranslations.DOB_FUTURE} />
            </ListItem>
          }
          {isEmpty(sexOption) &&
            <ListItem disablePadding>
              <ListItemText primary={langTranslations.SEX_EMPTY} />
            </ListItem>
          }
          {isEmpty(trim(email)) && age > 17 &&
            <ListItem disablePadding>
              <ListItemText primary={langTranslations.EMAIL_EMPTY} />
            </ListItem>
          }
          </List>
        </Alert>
      }
      */}
      <Fab
        color="secondary"
        arial-label="next"
        sx={{
          position: 'absolute',
          bottom: 16,
          right: 16
        }}
      >
        <ArrowForwardIcon onClick={handleContinue}/>
      </Fab>
    </Paper>
  )
}
